import { combineReducers, Reducer } from "redux";
import {
  reducer as flightSearch,
  initialState as flightSearchInitialState,
} from "../modules/search";
import {
  reducer as flightShop,
  initialState as flightShopInitialState,
  getExperimentState as getFlightShopExperimentState,
} from "../modules/shop";
import {
  reducer as flightBook,
  initialState as flightBookInitialState,
} from "../modules/book";
import {
  reducer as flightRewards,
  initialState as flightRewardsInitialState,
} from "../modules/rewards/reducer";
import {
  reducer as flightFreeze,
  initialState as flightFreezeInitialState,
  getExperimentState as getFlightFreezExperimentState,
} from "../modules/freeze/reducer";
import {
  reducer as flightAncillary,
  initialState as flightAncillaryInitialState,
  getExperimentState as getFlightAncillaryExperimentState,
} from "../modules/ancillary/reducer";
import { IStoreState, RESET_STATE } from "./types";
import {
  reducer as flightTravelWallet,
  initialState as flightTravelWalletInitialState,
} from "../modules/travel-wallet";
import {
  reducer as flightToHotelCrossSell,
  initialState as flightToHotelCrossSellInitialState,
} from "../modules/cross-sell";
import { chfarReducers } from "../modules/change-for-any-reason/reducers";
import {
  chfarFlightApiReducerPath,
  type IChFARFlightsApiState,
} from "../modules/change-for-any-reason/reducers/flights/api";
import { initialState as chfarExperimentsInitialState } from "../modules/change-for-any-reason/reducers/experiments/slice";
import {
  chfarLocationApiReducerPath,
  type IChFARLocationApiState,
} from "../modules/change-for-any-reason/reducers/location/api";

export const appReducer: Reducer = combineReducers({
  flightSearch,
  flightShop,
  flightBook,
  flightRewards,
  flightFreeze,
  flightTravelWallet,
  flightAncillary,
  flightToHotelCrossSell,
  ...chfarReducers,
});

export const rootReducer = (
  state: IStoreState | undefined,
  action: any
): IStoreState => {
  switch (action.type) {
    // note: this redux action does NOT modify LaunchDarkly experiments stored in redux
    case RESET_STATE:
      return {
        flightSearch:
          state?.flightSearch ??
          flightSearchInitialState(window?.location.search),
        flightShop: {
          ...flightShopInitialState,
          ...getFlightShopExperimentState(state?.flightShop),
        },
        flightBook: {
          ...flightBookInitialState,
          ...(state && {
            userPassengers: state?.flightBook.userPassengers,
            userPassengerCallState: state?.flightBook.userPassengerCallState,
          }),
        },
        flightRewards: state?.flightRewards ?? flightRewardsInitialState,
        flightFreeze: {
          ...flightFreezeInitialState,
          ...getFlightFreezExperimentState(state?.flightFreeze),
        },
        flightTravelWallet: flightTravelWalletInitialState,
        flightAncillary: {
          ...flightAncillaryInitialState,
          ...getFlightAncillaryExperimentState(state?.flightAncillary),
        },
        flightToHotelCrossSell: flightToHotelCrossSellInitialState,
        changeForAnyReason: {
          experiments: chfarExperimentsInitialState,
        },
        [chfarFlightApiReducerPath]:
          state?.chfarFlightsApi as IChFARFlightsApiState,
        [chfarLocationApiReducerPath]:
          state?.chfarLocationApi as IChFARLocationApiState,
      };

    default:
      break;
  }
  return appReducer(state, action);
};
