import {
  takeLatest,
  takeLeading,
  takeEvery,
  race,
  take,
  call,
} from "redux-saga/effects";

import { actions, actionTypes } from "../actions";
import {
  deleteUserPassengerSaga,
  updateUserPassengerSaga,
  fetchUserPassengersSaga,
  fetchCorpUserPassengersSaga,
} from "./user-passenger";
import {
  deletePaymentMethodSaga,
  fetchPaymentMethodSaga,
  listPaymentMethodsSaga,
  verifyPaymentMethodSaga,
} from "./payment-method";
import {
  scheduleQuoteSaga,
  pollQuoteSaga,
  validatePassengersSaga,
  schedulePaymentSaga,
  tripPricingSaga,
  chargeAgentBookingFeeSaga,
  fetchSeatMapSaga,
  fetchPriceDropEligibilitySaga,
  fetchApplicableTravelWalletItemsSaga,
  submitForApprovalSaga,
} from "./flight-book";
import { closeSessionSaga, setUpFlightBookParamsSaga } from "./session";
import { populateFlightBookQueryParamsSaga } from "./populateBookQueryParamsSaga";
import { fetchProductToEarnSaga } from "./fetchProductToEarnSaga";
import { pollFinalizedSaga } from "./flight-book/pollFinalizedSaga";
import { updateUserPassengersMultipleSaga } from "./user-passenger/updateUserPassengersMultipleSaga";
import { fetchAllEarnForProductSaga } from "./fetchAllEarnForProductSaga";

export function* watchSetUpQueryParams() {
  yield takeLatest(
    actionTypes.POPULATE_FLIGHT_BOOK_QUERY_PARAMS,
    populateFlightBookQueryParamsSaga
  );
}

export function* watchUpdateUserPassenger() {
  yield takeEvery(actionTypes.UPDATE_USER_PASSENGER, updateUserPassengerSaga);
}

export function* watchUpdateUserPassengers() {
  yield takeLeading(
    actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE,
    updateUserPassengersMultipleSaga
  );
}

export function* watchDeletePassengerSaga() {
  yield takeLeading(actionTypes.DELETE_USER_PASSENGER, deleteUserPassengerSaga);
}

export function* watchFetchUserPassengers() {
  yield takeLeading(actionTypes.FETCH_USER_PASSENGERS, fetchUserPassengersSaga);
}

export function* watchDeletePaymentMethodSaga() {
  yield takeLeading(actionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
}

export function* watchFetchPaymentMethodSaga() {
  yield takeLeading(actionTypes.FETCH_PAYMENT_METHOD, fetchPaymentMethodSaga);
}

export function* watchListPaymentMethodsSaga() {
  yield takeLeading(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}

export function* watchVerifyPaymentMethodSaga() {
  yield takeLeading(actionTypes.VERIFY_PAYMENT_METHOD, verifyPaymentMethodSaga);
}

export function* watchCloseSessionSaga() {
  yield takeLatest(actionTypes.CLOSE_SESSION, closeSessionSaga);
}

export function* watchSchedulePriceQuoteSaga() {
  yield takeLatest(
    actionTypes.SCHEDULE_QUOTE,
    function* (action: actions.IScheduleQuote) {
      yield race({
        task: call(scheduleQuoteSaga, action),
        cancel: take(actionTypes.SCHEDULED_QUOTE_CANCELLED),
      });
    }
  );
}

export function* watchPollPriceQuoteSaga() {
  yield takeLatest(actionTypes.POLL_QUOTE, pollQuoteSaga);
}

export function* watchSchedulePaymentSaga() {
  yield takeLeading(actionTypes.SCHEDULE_PAYMENT, schedulePaymentSaga);
}

export function* watchValidatePassengersSaga() {
  yield takeLatest(actionTypes.VALIDATE_PASSENGERS, validatePassengersSaga);
}

export function* watchTripPricingSaga() {
  yield takeLatest(actionTypes.FETCH_TRIP_PRICING, tripPricingSaga);
}

export function* watchPollFinalizedSaga() {
  yield takeLeading(actionTypes.POLL_FINALIZED, pollFinalizedSaga);
}

export function* watchProductToEarnSaga() {
  yield takeLeading(actionTypes.FETCH_PRODUCT_TO_EARN, fetchProductToEarnSaga);
}

export function* watchFetchAllEarnForProductSaga() {
  yield takeLeading(
    actionTypes.FETCH_ALL_EARN_FOR_PRODUCT,
    fetchAllEarnForProductSaga
  );
}

export function* watchSetUpFlightBookParamsSaga() {
  yield takeLeading(
    actionTypes.SET_UP_FLIGHT_BOOK_PARAMS,
    setUpFlightBookParamsSaga
  );
}

export function* watchChargeAgentBookingFeeSaga() {
  yield takeLeading(
    actionTypes.CHARGE_AGENT_BOOKING_FEE,
    chargeAgentBookingFeeSaga
  );
}

export function* watchSeatMapSaga() {
  yield takeLatest(actionTypes.FETCH_SEAT_MAP, fetchSeatMapSaga);
}

export function* watchFetchPriceDropEligibilitySaga() {
  yield takeLatest(
    actionTypes.FETCH_PRICE_DROP_ELIGIBILITY,
    fetchPriceDropEligibilitySaga
  );
}

export function* watchFetchApplicableTravelWalletItemsSaga() {
  yield takeLatest(
    actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
    fetchApplicableTravelWalletItemsSaga
  );
}

export function* watchSubmitForApprovalSaga() {
  yield takeLeading(actionTypes.SET_SUBMIT_FOR_APPROVAL, submitForApprovalSaga);
}

export function* watchFetchCorpUserPassengers() {
  yield takeLeading(
    actionTypes.FETCH_CORP_USER_PASSENGERS,
    fetchCorpUserPassengersSaga
  );
}
