import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { Box, Typography } from "@material-ui/core";
import {
  useDeviceTypes,
  LandingBenefits,
  usePrevious,
  CorporateTypography,
} from "halifax";
import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";

import "./styles.scss";
import { FlightSearchConnectorProps } from "./container";
import { FlightSearchControl } from "./components/FlightSearchControl";
import { FlightSearchControlV2 } from "./components/FlightSearchControlV2";
import { MobileFlightSearchControl } from "./components/MobileFlightSearchControl";
import * as constants from "./constants";
import { FLIGHT_SEARCH_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import {
  AIR_MULTICITY_EXPERIMENT,
  AVAILABLE,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
  CASH_VALUEPROP_EXPERIMENT,
  CASH_VALUEPROP_VARIANTS,
  CONTROL,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  useExperiments,
  RECENTLY_VIEWED_V2_FLIGHTS,
  AIR_FARECLASS_FILTER_EXPERIMENT,
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  CUSTOMER_PROFILE_EXPERIMENT,
  addTrackingProperties,
  CUSTOMER_PROFILE_VARIANTS,
  DEFAULT_ON,
} from "../../context/experiments";
import clsx from "clsx";
import { MobileFlightSearchControlV2 } from "./components/MobileFlightSearchControlV2";
import { MobileFlightSearchControlV3 } from "./components/MobileFlightSearchControlV3";
import { RouteComponentProps, useHistory } from "react-router";
import {
  APPLIED_PREFERENCES,
  AUTOCOMPLETE_LOCATION_SELECTED,
  AppliedPreferencesProperties,
  AutocompleteLocationSelectedProperties,
  CALENDAR_DATES_SELECTED,
  CalendarDatesSelectedProperties,
  CallState,
  FlightEntryTypeEnum,
  LAUNCHED_MOBILE_CALENDAR,
  LAUNCHED_MOBILE_LOCATION_SCREEN,
  LaunchedMobileCalendarProperties,
  LaunchedMobileLocationScreenProperties,
  RecentFlightSearch,
  Tenant,
  TripCategory,
} from "redmond";
import { transformToStringifiedRecentlySearchedFlightShopQuery } from "../shop/utils/parseQueryString";
import { PATH_SHOP } from "../../utils/urlPaths";
import { Skeleton } from "@material-ui/lab";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import dayjs from "dayjs";
import { MobileFlightSearchStep, initialFilterOptions } from "./reducer";
import queryStringParser from "query-string";
import { ClientContext } from "../../App";
import { config } from "../../api/config";
import {
  CORP_HOMEPAGE_SUBTITLE,
  CORP_HOMEPAGE_TITLE,
  CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT,
  isCaponeTenant,
  isCorpTenant,
} from "@capone/common";
import { SliceStopCountFilter } from "redmond/build";

interface IFlightSearchProps
  extends FlightSearchConnectorProps,
    RouteComponentProps {}

export const FlightSearch = (props: IFlightSearchProps) => {
  const {
    resetState,
    fetchRewardsAccounts,
    rewardsAccounts,
    largestValueAccount,
    fetchRewardsAccountsCallState,
    setFareclassOptionFilter,
    currentStep,
    fetchUserFlightPreferences,
    shouldApplyUserFlightPreferences,
    userFlightPreferences,
    setAirlineFilter,
    setStopsOption,
    origin,
    setOrigin,
    setApplyUserFlightPreferences,
    hasUserSetFlightPreferences,
    userFlightPreferencesCallState,
    listPaymentMethods,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();
  const [recentSearches, setRecentSearches] = useState<RecentFlightSearch[]>(
    []
  );

  const { policies, sessionInfo, isAutoApprovalEnabled } =
    useContext(ClientContext);

  const expState = useExperiments();
  const cashValuePropVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CASH_VALUEPROP_EXPERIMENT,
    CASH_VALUEPROP_VARIANTS
  );

  const recentlyViewedV2Flights = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_FLIGHTS
  );
  const isRecentlyViewedFlightsV2Experiment = useMemo(() => {
    return recentlyViewedV2Flights === AVAILABLE;
  }, [recentlyViewedV2Flights]);

  const airCXV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ),
    [expState.experiments]
  );

  const isAirCXV3Experiment = airCXV3Variant !== CONTROL;

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  React.useEffect(() => {
    document.title = FLIGHT_SEARCH_TITLE;
    resetState();
    fetchRewardsAccounts(false, sessionInfo);
    listPaymentMethods();
    window.scrollTo(0, 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  React.useEffect(() => {
    if (matchesMobile) {
      const { entryType } = queryStringParser.parse(history.location.search);
      const properties: LaunchedMobileLocationScreenProperties = {
        funnel: "flights",
        url: window.location.pathname,
        entry_type: entryType as string,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_LOCATION_SCREEN, properties });
    }
  }, [matchesMobile]);

  const handleSelectLocation = useCallback((value: any) => {
    if (value?.label) {
      const properties: AutocompleteLocationSelectedProperties = {
        funnel: "flights",
        url: window.location.pathname,
        autocomplete_value: value.label,
      };
      trackEvent({ eventName: AUTOCOMPLETE_LOCATION_SELECTED, properties });
    }
  }, []);

  const handleSelectDates = useCallback(
    (departure: Date | null, returnDate: Date | null) => {
      if (departure && returnDate) {
        const properties: CalendarDatesSelectedProperties = {
          funnel: "flights",
          url: window.location.pathname,
          start_date: dayjs(departure).format("YYYY-MM-DD"),
          end_date: dayjs(returnDate).format("YYYY-MM-DD"),
        };
        trackEvent({ eventName: CALENDAR_DATES_SELECTED, properties });
      }
    },
    []
  );

  useEffect(() => {
    if (
      matchesMobile &&
      currentStep === MobileFlightSearchStep.CalendarPicker
    ) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "flights",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, [matchesMobile, currentStep]);

  useEffect(() => {
    const getRecentSearches = () => {
      try {
        const localStorageItem = localStorage.getItem(
          "recently_searched_flights"
        );

        if (localStorageItem) {
          const parsedSearches: RecentFlightSearch[] =
            JSON.parse(localStorageItem);
          const sortedSearches = parsedSearches
            .filter((search) => !!search.originLocation)
            .sort(
              (a, b) =>
                new Date(b.searchDate).getTime() -
                new Date(a.searchDate).getTime()
            );
          setRecentSearches(sortedSearches);
        }
      } catch (e) {
        console.error(`Failed to get recent searches from local storage: ${e}`);
      }
    };
    getRecentSearches();
    addEventListener("update_recently_searched_flights", getRecentSearches);
    return () =>
      removeEventListener(
        "update_recently_searched_flights",
        getRecentSearches
      );
  }, []);

  const onRecentSearchClick = useCallback(
    (search: RecentFlightSearch) => {
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      });

      history.push(
        `${PATH_SHOP}${transformToStringifiedRecentlySearchedFlightShopQuery({
          tripCategory: search.returnDate
            ? TripCategory.ROUND_TRIP
            : TripCategory.ONE_WAY,
          origin: search.originLocation,
          destination: search.destinationLocation,
          departureDate: search.departureDate,
          returnDate: search.returnDate,
          stopsOptions: undefined,
          noLCC: false,
          flightShopProgress: undefined,
          adultsCounts: search.numAdults,
          childrenCount: search.numChildren,
          infantsInSeatsCount: search.numInfantsSeat,
          infantsOnLapCount: search.numInfantsLap,
          entryPoint: FlightEntryTypeEnum.RECENTLY_SEARCH_AUTOCOMPLETE,
          fareClass: search.fareClass,
        })}`,
        { fromPage: location.pathname }
      );
    },

    [history]
  );

  const hasOnlyCashCards = React.useMemo(
    () =>
      rewardsAccounts?.length > 0 &&
      rewardsAccounts.every(
        (account) => account.rewardsBalance.currency.toLowerCase() === "cash"
      ),
    [rewardsAccounts]
  );
  const benefitsVariant = React.useMemo(() => {
    switch (cashValuePropVariant) {
      case CONTROL:
      default:
        return [];
      case CASH_VALUEPROP_A:
        return constants.CASH_CARD_BENEFITS.VARIANT_A;
      case CASH_VALUEPROP_B:
        return constants.CASH_CARD_BENEFITS.VARIANT_B;
      case CASH_VALUEPROP_C:
        return constants.CASH_CARD_BENEFITS.VARIANT_C;
    }
  }, [cashValuePropVariant]);

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const hasEarnToDisplay = useMemo(() => {
    return (
      !!largestValueAccount && !!largestValueAccount.earn.flightsMultiplier
    );
  }, [largestValueAccount]);

  const showDBCCustomHeader =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const isOnboardingRevampAvailable = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "available"
  );
  const isOnboardingRevampDebug = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "debug"
  );
  const isCorpOnboardingRevampEnabled =
    isOnboardingRevampAvailable || isOnboardingRevampDebug;

  const corpTitle = showDBCCustomHeader
    ? CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT
    : CORP_HOMEPAGE_TITLE;

  const getTitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return corpTitle;
    } else if (
      fetchRewardsAccountsCallState === CallState.Success &&
      hasEarnToDisplay
    ) {
      return constants.EARN_ENHANCEMENT_TITLE(
        largestValueAccount.earn.flightsMultiplier,
        largestValueAccount.productDisplayName
      );
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      if (
        largestValueAccount?.productDisplayName
          .toLowerCase()
          .includes("paradise")
      ) {
        return <span className="font-regular">{constants.PARADISE_TITLE}</span>;
      } else {
        return <span className="font-regular">{constants.TITLE}</span>;
      }
    } else {
      return <Skeleton className="title-loading" />;
    }
  };

  const getSubtitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return isCorpOnboardingRevampEnabled ? (
        <CorporateTypography variant="h5" fontWeight="light">
          {CORP_HOMEPAGE_SUBTITLE}
        </CorporateTypography>
      ) : (
        CORP_HOMEPAGE_SUBTITLE
      );
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      return constants.SUBTITLE;
    } else {
      return <Skeleton className="subtitle-loading" />;
    }
  };

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isMultiCityEnabledExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, AIR_MULTICITY_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isMultiCityEnabled =
    isMultiCityEnabledExperiment &&
    (isCaponeTenant(config.TENANT) ||
      isAutoApprovalEnabled ||
      isApprovalsV2Enabled);

  const isAirFareClassFilterEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        AIR_FARECLASS_FILTER_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const customerProfileExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CUSTOMER_PROFILE_EXPERIMENT,
    CUSTOMER_PROFILE_VARIANTS
  );

  const isCustomerProfileExperiment =
    customerProfileExperimentVariant !== CONTROL;

  const prevShouldApplyUserFlightPreferences = usePrevious(
    shouldApplyUserFlightPreferences
  );

  useEffect(() => {
    if (
      customerProfileExperimentVariant === DEFAULT_ON &&
      userFlightPreferencesCallState === CallState.Success &&
      hasUserSetFlightPreferences
    ) {
      setApplyUserFlightPreferences(true);
    }
  }, [
    customerProfileExperimentVariant,
    hasUserSetFlightPreferences,
    userFlightPreferencesCallState,
  ]);

  useEffect(() => {
    fetchUserFlightPreferences();
  }, []);

  useEffect(() => {
    if (isCustomerProfileExperiment && userFlightPreferences) {
      if (!origin && userFlightPreferences.homeAirport) {
        setOrigin(userFlightPreferences.homeAirport);
      }

      if (shouldApplyUserFlightPreferences) {
        if (!!userFlightPreferences.airlines.length) {
          setAirlineFilter(userFlightPreferences.airlines);
        }

        if (
          Object.values(userFlightPreferences.fareClasses).some(
            (applied) => applied
          )
        ) {
          setFareclassOptionFilter(userFlightPreferences.fareClasses);
        }
        if (userFlightPreferences.nonStopOnly) {
          setStopsOption(SliceStopCountFilter.NONE);
        }
      }
    }

    if (
      isCustomerProfileExperiment &&
      !shouldApplyUserFlightPreferences &&
      shouldApplyUserFlightPreferences !== prevShouldApplyUserFlightPreferences
    ) {
      setAirlineFilter(initialFilterOptions.airlineFilter);
      setFareclassOptionFilter(initialFilterOptions.fareclassOptionFilter);
      setStopsOption(initialFilterOptions.stopsOption);
    }
  }, [
    isCustomerProfileExperiment,
    shouldApplyUserFlightPreferences,
    userFlightPreferences,
    prevShouldApplyUserFlightPreferences,
  ]);

  useEffect(() => {
    if (
      prevShouldApplyUserFlightPreferences === false &&
      shouldApplyUserFlightPreferences === true
    ) {
      const properties: AppliedPreferencesProperties = {
        preferences_type: "flights",
        location: "search",
      };

      trackEvent({
        eventName: APPLIED_PREFERENCES,
        properties: addTrackingProperties(
          expState.trackingProperties,
          properties
        ),
      });
    }
  }, [shouldApplyUserFlightPreferences, prevShouldApplyUserFlightPreferences]);

  return (
    <>
      {matchesMobile ? (
        <Box
          className={clsx("mobile-flight-search-root", {
            "redesign-v2":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
            "redesign-v3":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3 ||
              isMultiCityEnabled,
          })}
        >
          <Box className="flight-search-container-mobile">
            {mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3 ||
            isMultiCityEnabled ? (
              <MobileFlightSearchControlV3
                isMultiCityEnabled={isMultiCityEnabled}
                recentSearches={
                  isRecentlyViewedFlightsV2Experiment
                    ? recentSearches
                    : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                onSelectLocation={handleSelectLocation}
                onSelectDates={handleSelectDates}
                isAirCXV3Experiment={airCXV3Variant !== CONTROL}
                isCustomerProfileExperiment={isCustomerProfileExperiment}
              />
            ) : mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2 ? (
              <>
                <MobileFlightSearchControlV2
                  recentSearches={
                    isRecentlyViewedFlightsV2Experiment
                      ? recentSearches
                      : undefined
                  }
                  onRecentSearchClick={onRecentSearchClick}
                  onSelectLocation={handleSelectLocation}
                  onSelectDates={handleSelectDates}
                />
                <Box className="img-container"></Box>
              </>
            ) : (
              <MobileFlightSearchControl
                onSelectLocation={handleSelectLocation}
                onSelectDates={handleSelectDates}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box className="flight-search-root">
          <Box
            className={clsx("flight-search-container-desktop", config.TENANT)}
          >
            <Box className={clsx("flight-search-titles-and-fields-wrapper")}>
              {isCorpOnboardingRevampEnabled ? (
                <CorporateTypography variant="h1" className="search-title">
                  {corpTitle}
                </CorporateTypography>
              ) : (
                <Typography variant="h1" className="search-title">
                  {getTitleToDisplay(config.TENANT)}
                </Typography>
              )}
              <Typography variant="h5" className="search-subtitle">
                {getSubtitleToDisplay(config.TENANT)}
              </Typography>
              {isMultiCityEnabled || isFlightListOptimizationExperiment ? (
                <FlightSearchControlV2
                  isMultiCityEnabled={isMultiCityEnabled}
                  recentSearches={
                    isRecentlyViewedFlightsV2Experiment
                      ? recentSearches
                      : undefined
                  }
                  onRecentSearchClick={onRecentSearchClick}
                  showFareClassFilter={isAirFareClassFilterEnabled}
                  showNonStopToggle={isAirCXV3Experiment}
                  isCustomerProfileExperiment={isCustomerProfileExperiment}
                />
              ) : (
                <FlightSearchControl
                  sessionInfo={sessionInfo}
                  policies={isPolicyDescriptorsEnabled ? policies : undefined}
                />
              )}
            </Box>
          </Box>
          {cashValuePropVariant !== CONTROL && hasOnlyCashCards && (
            <LandingBenefits
              title={constants.CASH_CARD_BENEFITS_TITLE(
                rewardsAccounts?.[0]?.productDisplayName
              )}
              benefits={benefitsVariant}
            />
          )}
        </Box>
      )}
    </>
  );
};
