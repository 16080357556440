import React, {useEffect, useMemo} from "react";
import { RouteComponentProps } from "react-router";
import {LoadingIndicator, B2BSpinner, PricingBreakdown, ISummaryLineItem} from "halifax";
import {CallState, FiatPrice} from "redmond";
import clsx from "clsx";
import { PriceBreakdownConnectorProps } from "./container";
import "./styles.scss";

export interface IPriceBreakdownProps
  extends PriceBreakdownConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  className?: string;
  chfarExerciseAmount?: FiatPrice;
}

export const PriceBreakdown = ({
  tripPricingCallState,
  currency,
  lineItems,
  summaryLineItems,
  pricingParams,
  fetchTripPricing,
  className,
  cfarOfferText,
  isVITripSelected,
  ancillaries,
  chfarExerciseAmount,
}: IPriceBreakdownProps) => {
  useEffect(() => {
    fetchTripPricing();
  }, [
    pricingParams.ancillaryIds,
    pricingParams.tripId,
    pricingParams.fareId,
    pricingParams.priceFreezeId,
    pricingParams.lapInfants.length,
    pricingParams.seatedPassengers.length,
    fetchTripPricing,
  ]);

  const updatedSummaryLineItems = useMemo(() => {
    const items = [...summaryLineItems];
    if (chfarExerciseAmount) {
      const customItem = {
        "type": "custom",
        "label": "Change your flight for any reason applied:",
        "fiatPrice": chfarExerciseAmount,
        "className": "chfar-exercise-amount",
      } as ISummaryLineItem;
      if (items.length > 1) {
        items.splice(1, 0, customItem);
      } else {
        items.push(customItem);
      }
    } 
    return items;
  }, [summaryLineItems, chfarExerciseAmount])


  return (
    <>
      {tripPricingCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message="Fetching latest price"
         />
      ) : (
        <PricingBreakdown
          className={clsx("price-breakdown", className)}
          pricingItems={lineItems}
          summaryItems={updatedSummaryLineItems}
          currencySymbol={currency.currencySymbol}
          currencyCode={currency.currencyCode}
          isVITripSelected={isVITripSelected}
          ancillaries={{
            ...ancillaries,
            cfarText: cfarOfferText,
          }}
        />
      )}
    </>
  );
};
