import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  schedulePayment,
  scheduleQuote,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setSelectedSeats,
  setQuote,
  fetchApplicableTravelWalletItems,
  setUserTcpaConsentPhoneNumber,
  setUserSelectedPassengerIds,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { DesktopFlightBookWorkflow } from "./component";
import {
  getSelectedPaymentMethodId,
  getFinalizedItinerary,
  getSession,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getPriceDifferenceAcknowledged,
  getPriceDropProperties,
  getIsConfirmAndBookReady,
  getPriceQuoteWithUpdatedAncillary,
  getPriceDifference,
  isTravelerStepCompleteSelector,
  getIsPriceFreezeExerciseEnabled,
  getIsWaitingPriceQuote,
  getPriceQuoteErrors,
  getBookingProgressList,
  getCheckoutStepNumberSelector,
  currentPriceDropProtectionSelector,
  getFlightBookType,
  getPaymentsV2,
  getAncillaries,
  hasActiveRefundableFareInFlightBookSelector,
  getIsTripVoidWindowEligible,
  getIsTravelWalletPaymentOnly,
} from "../../../reducer";
import { getTripCategory } from "../../../../search/reducer/selectors";
import {
  isCustomizePageMarketplaceEnabledSelector,
  isDisruptionProtectionEnabledSelector,
  isFlightBookWithAncillariesEnabledSelector,
} from "../../../../ancillary/reducer/selectors";
import {
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  selectedTripSelector,
  isOptionSelectionCompleteSelector,
  isFlightBookWithAncillariesActiveSelector,
  fetchAncillaryOfferCallStateSelector,
  airportsSelector,
  tripDetailsSelector,
  getViewedTripSummaryProperties,
  selectedDisruptionProtectionOfferDelayThresholdSelector,
} from "../../../../shop/reducer/selectors";
import {
  hasSMSBeenSelected,
  hasAppNotifBeenSelected,
  hasDisruptionProtectionBeenAttachedSelector,
  getIsTripInternational,
  getPricing,
} from "../../../../book/reducer/selectors";
import {
  isDisruptionOptInEnabledSelector,
  isNonFDADisruptionOptInEnabledSelector,
} from "../../../../ancillary/reducer/selectors/disruptionProtection";
import {
  setFlightShopProgress,
  setMulticityFlightShopProgress,
  resetFetchAncillaryOfferState,
  fetchAncillaryOffer,
} from "../../../../shop/actions/actions";
import { fetchInitialCrossSellHotelAvailability } from "../../../../cross-sell/actions/actions";
import { fetchTravelWalletCreditHistory } from "../../../../travel-wallet/actions/actions";
import { isCfarMulticityEnabledSelector } from "../../../../shop/reducer/selectors/experiments";
import { getPotentialCrossSellOffers } from "../../../../cross-sell/reducer/selectors";

export const mapStateToProps = (state: IStoreState) => ({
  currentSession: getSession(state),
  paymentMethodId: getSelectedPaymentMethodId(state),
  finalizedItinerary: getFinalizedItinerary(state),
  selectedTrip: selectedTripSelector(state),
  confirmationEmail: getConfirmationEmail(state),
  confirmationPhoneNumber: getConfirmationPhoneNumber(state),
  isConfirmAndBookReady: getIsConfirmAndBookReady(state),
  priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
  payments: getPaymentsV2(state),
  ancillaries: getAncillaries(state),
  priceDropProtection: currentPriceDropProtectionSelector(state),
  priceDropViewedProperties: getPriceDropProperties(state),
  priceQuote: getPriceQuoteWithUpdatedAncillary(state),
  hasDifference: getPriceDifference(state).hasDifference,
  isTravelerStepComplete: isTravelerStepCompleteSelector(state),
  isPriceFreezeExerciseEnabled: getIsPriceFreezeExerciseEnabled(state),
  isCustomizePageMarketplaceEnabled:
    isCustomizePageMarketplaceEnabledSelector(state),
  isFlightBookWithAncillariesEnabled:
    isFlightBookWithAncillariesEnabledSelector(state),
  isFlightBookWithAncillariesActive:
    isFlightBookWithAncillariesActiveSelector(state),
  isDisruptionProtectionEnabled: isDisruptionProtectionEnabledSelector(state),
  isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
  fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
  isWaitingPriceQuote: getIsWaitingPriceQuote(state),
  priceQuoteErrors: getPriceQuoteErrors(state),
  bookingProgressList: getBookingProgressList(state),
  getCheckoutStepNumber: getCheckoutStepNumberSelector(state),
  flightShopProgress: flightShopProgressSelector(state),
  multicityFlightShopProgress: flightShopMulticityProgressSelector(state),
  tripCategory: getTripCategory(state),
  hasActiveRefundableFare: hasActiveRefundableFareInFlightBookSelector(state),
  flightBookType: getFlightBookType(state),
  airports: airportsSelector(state, selectedTripSelector(state)?.tripId || ""),
  tripDetails: tripDetailsSelector(
    state,
    selectedTripSelector(state)?.tripId || ""
  ),
  isVoidWindowEligible: getIsTripVoidWindowEligible(state),
  isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
  smsBeenSelected: hasSMSBeenSelected(state),
  appNotifBeenSelected: hasAppNotifBeenSelected(state),
  hasSelectedDisruption: hasDisruptionProtectionBeenAttachedSelector(state),
  disruptionOptInEnabled: isDisruptionOptInEnabledSelector(state),
  nonFDAdisruptionOptInEnabled: isNonFDADisruptionOptInEnabledSelector(state),
  isInternationalTrip: getIsTripInternational(state),
  isCfarMulticityEnabled: isCfarMulticityEnabledSelector(state),
  viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
  disruptionDelayThreshold:
    selectedDisruptionProtectionOfferDelayThresholdSelector(state),
  potentialCrossSellOffers: getPotentialCrossSellOffers(state),
  pricing: getPricing(state),
});

export const mapDispatchToProps = {
  setContactInfo,
  schedulePayment,
  scheduleQuote,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setSelectedSeats,
  setQuote,
  setFlightShopProgress,
  setMulticityFlightShopProgress,
  fetchApplicableTravelWalletItems,
  resetFetchAncillaryOfferState,
  fetchAncillaryOffer,
  fetchInitialCrossSellHotelAvailability,
  fetchTravelWalletCreditHistory,
  setUserTcpaConsentPhoneNumber,
  setUserSelectedPassengerIds,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopFlightBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopFlightBookWorkflow = withRouter(
  connector(DesktopFlightBookWorkflow)
);
