import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CallState } from "redmond";
import {
  schedulePayment,
  scheduleQuote,
  setContactInfo,
  resetPaymentCardSelectedAccounts,
  fetchApplicableTravelWalletItems,
  setUserTcpaConsentPhoneNumber,
  setUserSelectedPassengerIds,
  toggleAppNotifOptIn,
  toggleSMSOptIn,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { MobileFlightBookWorkflow } from "./component";
import {
  getIsBookingValid,
  getSelectedPaymentMethodId,
  hasNoUserPassengersSelector,
  getFinalizedItinerary,
  getSession,
  getTripPricingCallState,
  getPassengersValidCallState,
  getTripPricing,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getPriceDropProperties,
  getPriceQuoteWithUpdatedAncillary,
  getSeatMapAvailability,
  getIsWaitingPriceQuote,
  getSeatMapLoading,
  currentPriceDropProtectionSelector,
  getRewardsPaymentAccountReferenceId,
  getErrorTitles,
  getHasError,
  getIsMissingPassport,
  getFlightBookType,
  getOffers,
  getPaymentsV2,
  getAncillaries,
  getCredit,
  getIsTripVoidWindowEligible,
  getIsTravelWalletPaymentOnly,
  getCreditToApply,
} from "../../../reducer";
import { isDisruptionOptInEnabledSelector } from "../../../../ancillary/reducer/selectors/disruptionProtection";
import {
  airportsSelector,
  selectedDisruptionProtectionOfferDelayThresholdSelector,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../../shop/reducer/selectors";
import {
  populateFlightShopQueryParams,
  setFlightShopProgress,
  setMulticityFlightShopProgress,
} from "../../../../shop/actions/actions";
import { fetchInitialCrossSellHotelAvailability } from "../../../../cross-sell/actions/actions";
import { fetchTravelWalletCreditHistory } from "../../../../travel-wallet/actions/actions";
import {
  hasDisruptionProtectionBeenAttachedSelector,
  hasSMSBeenSelected,
  hasAppNotifBeenSelected,
  getUserTcpaConsentPhoneNumber,
  getIsTripInternational,
  getPricing,
} from "../../../reducer/selectors";
import { getTripCategory } from "../../../../search/reducer";
import { getPotentialCrossSellOffers } from "../../../../cross-sell/reducer/selectors";

export const mapStateToProps = (state: IStoreState) => ({
  currentSession: getSession(state),
  hasTripPricing: !!getTripPricing(state),
  isWaitingValidation:
    getTripPricingCallState(state) === CallState.InProcess ||
    getPassengersValidCallState(state) === CallState.InProcess,
  paymentMethodId: getSelectedPaymentMethodId(state),
  isBookingValid: getIsBookingValid(state),
  hasNoUserPassengers: hasNoUserPassengersSelector(state),
  selectedTrip: selectedTripSelector(state),
  finalizedItinerary: getFinalizedItinerary(state),
  confirmationEmail: getConfirmationEmail(state),
  confirmationPhoneNumber: getConfirmationPhoneNumber(state),
  priceDropProtection: currentPriceDropProtectionSelector(state),
  priceDropViewedProperties: getPriceDropProperties(state),
  priceQuote: getPriceQuoteWithUpdatedAncillary(state),
  payments: getPaymentsV2(state),
  ancillaries: getAncillaries(state),
  seatMapAvailability: getSeatMapAvailability(state),
  isWaitingPriceQuote: getIsWaitingPriceQuote(state),
  seatMapLoading: getSeatMapLoading(state),
  rewardsPaymentAccountReferenceId: getRewardsPaymentAccountReferenceId(state),
  errorTitles: getErrorTitles(state),
  hasError: getHasError(state),
  missingPassport: getIsMissingPassport(state),
  flightBookType: getFlightBookType(state),
  offers: getOffers(state),
  credit: getCredit(state),
  airports: airportsSelector(state, selectedTripSelector(state)?.tripId || ""),
  tripDetails: tripDetailsSelector(
    state,
    selectedTripSelector(state)?.tripId || ""
  ),
  isVoidWindowEligible: getIsTripVoidWindowEligible(state),
  isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
  priceQuoteInProgress: getIsWaitingPriceQuote(state),
  creditToApply: getCreditToApply(state),
  hasSelectedDisruption: hasDisruptionProtectionBeenAttachedSelector(state),
  smsBeenSelected: hasSMSBeenSelected(state),
  appNotifBeenSelected: hasAppNotifBeenSelected(state),
  disruptionOptInEnabled: isDisruptionOptInEnabledSelector(state),
  tcpaConsentPhoneNumber: getUserTcpaConsentPhoneNumber(state),
  isInternationalFlight: getIsTripInternational(state),
  tripCategory: getTripCategory(state),
  disruptionDelayThreshold:
    selectedDisruptionProtectionOfferDelayThresholdSelector(state),
  potentialCrossSellOffers: getPotentialCrossSellOffers(state),
  pricing: getPricing(state),
});

export const mapDispatchToProps = {
  scheduleQuote,
  setContactInfo,
  populateFlightShopQueryParams,
  schedulePayment,
  resetPaymentCardSelectedAccounts,
  setFlightShopProgress,
  fetchApplicableTravelWalletItems,
  fetchInitialCrossSellHotelAvailability,
  fetchTravelWalletCreditHistory,
  toggleAppNotifOptIn,
  toggleSMSOptIn,
  setUserTcpaConsentPhoneNumber,
  setMulticityFlightShopProgress,
  setUserSelectedPassengerIds,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightBookWorkflow = withRouter(
  connector(MobileFlightBookWorkflow)
);
